import React from 'react'
import { AppContext } from '../../App'
//import { setContracts, setSettlements, fileNotification } from '../../services/axiosService.js'

function ModalConfirmation() {
  const {
    state: authState,
    confirmationModalShowed,
    setConfirmationModalShowed,
  } = React.useContext(AppContext)

  document.addEventListener('keyup', (e) => {
    // if we press the ESC
    if (e.key === 'Escape' && document.querySelector('.modal-background')) {
      setConfirmationModalShowed({
        enabled: false,
        action: null,
        type: null,
        item: null,
      })
    }
  })

  document.addEventListener('click', (e) => {
    if (e.target === document.querySelector('.modal-background')) {
      setConfirmationModalShowed({
        enabled: false,
        action: null,
        type: null,
        item: null,
      })
    }
  })

  /*const handleClick = async (confirmationObj) => {
    if (confirmationObj.action === 4) {
      if (confirmationObj.item === 'notification') {
        await fileNotification(authState.token, confirmationObj.itemId)
          .then((response) => {
            //console.log(response)
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => null)
      }
    } else if (confirmationObj.action === 2 || confirmationObj.action === 3) {
      var enabled
      enabled = confirmationObj.action === 2 ? 1 : confirmationObj.action === 3 ? 0 : null
      if (confirmationObj.item === 'contract') {
        await setContracts(authState.token, confirmationObj.itemId, enabled)
          .then((response) => {
            //console.log(response)
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => null)
      } else if (confirmationObj.item === 'settlement') {
        await setSettlements(authState.token, confirmationObj.itemId, enabled)
          .then((response) => {
            //console.log(response)
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => null)
      }
    }
    setTimeout(() => {
      setConfirmationModalShowed(false)
    }, 1500)
  }*/

  return (
    <div
      id="modal-confirm-section"
      className={confirmationModalShowed.enabled ? 'modal-background' : 'd-none'}
    >
      <div className="modal-confirm-container d-flex flex-column justify-content-center align-items-center">
        <h3>
          {confirmationModalShowed.action === 'delete'
            ? 'Confirmación de borrado'
            : confirmationModalShowed.action === 'edit'
            ? 'Confirmación de edición'
            : confirmationModalShowed.action === 'create'
            ? 'Confirmación de creado'
            : confirmationModalShowed.action === 'duplicate'
            ? 'Confirmación de Duplicado'
            : ''}
        </h3>
        <h4 className="mb-4">
          {`¿Esta seguro que quiere ${
            confirmationModalShowed.action === 'delete'
              ? 'borrar'
              : confirmationModalShowed.action === 'edit'
              ? 'editar'
              : confirmationModalShowed.action === 'create'
              ? 'crear'
              : confirmationModalShowed.action === 'duplicate'
              ? 'duplicar'
              : ''
          } el ${
            confirmationModalShowed.type === 'benefit'
              ? 'beneficio'
              : confirmationModalShowed.type === 'product'
              ? 'producto'
              : ''
          }${confirmationModalShowed.item.id ? ' con id ' + confirmationModalShowed.item.id : ''}${
            confirmationModalShowed.item.name
              ? ' y con nombre ' + confirmationModalShowed.item.name
              : ''
          }?`}
        </h4>
        <div className="w-100 d-flex flex-row justify-content-between align-items-center">
          <button
            className="d-flex flex-row justify-content-center align-items-center"
            onClick={() =>
              setConfirmationModalShowed({
                enabled: false,
                action: null,
                type: null,
                item: null,
              })
            }
          >
            Atrás
          </button>
          <button
            className="d-flex flex-row justify-content-center align-items-center"
            onClick={() => null}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalConfirmation
