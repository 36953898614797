import React, { Suspense, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import './scss/style.scss'
import { CSpinner } from '@coreui/react'
import ModalContainer from './views/modals/ModalContainer'
import ModalConfirmation from './views/modals/ModalConfirmation'

export const AppContext = React.createContext()

const initialState = {
  isAuthenticated: sessionStorage.getItem('token') ? true : false,
  username: sessionStorage.getItem('token')
    ? jwtDecode(sessionStorage.getItem('token').replace(/['"]+/g, '')).username
    : null,
  token: sessionStorage.getItem('token')
    ? sessionStorage.getItem('token').replace(/['"]+/g, '')
    : null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      sessionStorage.setItem('token', JSON.stringify(action.payload.data))
      return {
        ...state,
        isAuthenticated: true,
        username: jwtDecode(action.payload.data).username,
        token: action.payload.data,
      }
    case 'LOGOUT':
      sessionStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        username: null,
        token: null,
      }
    default:
      return state
  }
}

const loading = (
  <div className="w-100 p-3 d-flex justify-content-center">
    <CSpinner color="dark" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const [state, dispatchState] = React.useReducer(reducer, initialState)
  const [confirmationModalShowed, setConfirmationModalShowed] = useState({
    enabled: false,
    action: null,
    type: null,
    item: null,
  })

  return (
    <AppContext.Provider
      value={{
        state,
        dispatchState,
        confirmationModalShowed,
        setConfirmationModalShowed,
      }}
    >
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/"
              name="Login Page"
              element={state.isAuthenticated ? <DefaultLayout /> : <Login />}
            />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              path="*"
              name="Home"
              element={state.isAuthenticated ? <DefaultLayout /> : <Page404 />}
            />
          </Routes>
          <ModalContainer>
            {confirmationModalShowed.enabled ? <ModalConfirmation /> : null}
          </ModalContainer>
        </Suspense>
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App
